import alehaLogo from "../../static/images/aleha_logo.webp";
import augsburger_allgemeine_logo from "../../static/images/augsburger_allgemeine_logo.webp";
import ChemieClusterLogo from "../../static/images/chemiecluster-logo.webp";
import circularValeyLogo from "../../static/images/Circular-Valley_Logo.webp";
import CMLogo from "../../static/images/CM-logo.webp";
import dynamicAirLogo from "../../static/images/dynamicAir_logo.webp";
import friedberger_allgemeine_logo from "../../static/images/friedberger_allgemeine_logo.webp";
import HTDigitalHubChemistryHealthLogo from "../../static/images/HT-Digital-Hub-Chemistry-Health-Logo.webp";
import IHK_Schwaben_logo from "../../static/images/IHK_Schwaben_logo.webp";
import polinimLogo from "../../static/images/polinim_logo.webp";
import roCoCo_TBC_logo from "../../static/images/roCoCo_TBC_logo.webp";
import SAPSLogo from "../../static/images/SAPS_logo.webp";
import WAA_Full_Logo from "../../static/images/WAA_Full_Logo.webp";
import wiwaxLogo from "../../static/images/wiwax_logo.webp";
export const allLogosOptions = [
  {
    value: "aleha" as const,
    logo: alehaLogo
  },
  {
    value: "circularValey" as const,
    logo: circularValeyLogo
  },
  {
    value: "dynamicAir" as const,
    logo: dynamicAirLogo
  },
  {
    value: "polinim" as const,
    logo: polinimLogo
  },
  {
    value: "roCoCo" as const,
    logo: roCoCo_TBC_logo
  },
  {
    value: "SAPS" as const,
    logo: SAPSLogo
  },
  {
    value: "wiwax" as const,
    logo: wiwaxLogo
  },
  {
    value: "ChemieCluster" as const,
    logo: ChemieClusterLogo
  },
  {
    value: "HTDigitalHubChemistryHealth" as const,
    logo: HTDigitalHubChemistryHealthLogo
  },
  {
    value: "CM" as const,
    logo: CMLogo
  },
  {
    value: "augsburger_allgemeine" as const,
    logo: augsburger_allgemeine_logo
  },
  {
    value: "IHK_Schwaben" as const,
    logo: IHK_Schwaben_logo
  },
  {
    value: "friedberger_allgemeine" as const,
    logo: friedberger_allgemeine_logo
  },
  {
    value: "Wide_angle_analytics" as const,
    logo: WAA_Full_Logo
  }
];

export type AppLogo = ArrayElement<typeof allLogosOptions>["value"];

import React from "react";
import tw, { css } from "twin.macro";

interface Props {
  Logo: string;
  alternate: string;
  withCard?: boolean;
  withURL?: string;
}

const card = css`
  ${tw`hover:ring-1 hover:ring-grey-light rounded-md m-2 hover:shadow-md`}
`;

const PartnerLogo: React.FC<Props> = props => {
  const { Logo } = props;
  const { withCard } = props;
  const { withURL } = props;
  const { alternate } = props;
  const { ...rest } = props;

  return (
    <a css={withCard && card} href={withURL} {...rest}>
      <img src={Logo} alt={alternate} />
    </a>
  );
};

export default PartnerLogo;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import tw, { css } from "twin.macro";

import { allLogosOptions } from "../consts/allLogos";
import PartnerLogo from "./PartnerLogo";

const arrows = css`
  ${tw`border-turquoise-dark`}
`;

const logos = css`
  .slick-slide {
    > div {
      ${tw`flex m-auto h-40`}
      > a {
        ${tw`m-auto`}
        > img {
          ${tw`w-2/3 mx-auto max-h-32`}
        }
      }
    }
  }
  .slick-next,
  .slick-prev {
    ${tw`absolute text-opacity-0 top-16 w-8 h-8 leading-7 border-2 border-solid border-white border-turquoise-dark rounded-full text-transparent`}
  }
  .slick-next {
    ${tw`right-0 z-10`}
  }
  .slick-prev {
    ${tw`left-0 z-10`}
  }
  .slick-next::before {
    ${tw`inline-block mt-0 -ml-1 w-3.5 h-3.5 border-t-4 border-r-4 border-solid transform rotate-45`}
    content: '';
    ${arrows}
  }
  .slick-prev::before {
    ${tw`inline-block mt-0 ml-1 w-3.5 h-3.5 border-b-4 border-l-4 border-solid transform rotate-45`}
    ${arrows}
    content: '';
  }
  .slick-dots {
    ${tw`absolute block text-center w-full p-0 m-0 -bottom-3`}
  }
  .slick-dots li {
    ${tw`relative inline-block mx-1 w-5 h-5 p-0 cursor-pointer opacity-100`}
  }
  .slick-dots li button {
    ${tw`block leading-3 w-3.5 h-3.5 p-0 cursor-pointer bg-opacity-0 text-zero`}
  }
  .slick-dots li button:before {
    ${tw`absolute top-0 left-0 w-4 h-4 text-center text-2xl opacity-25 font-black leading-none`}
    content: '•';
  }

  .slick-dots li button:focus:before {
    ${tw`opacity-100 border-none`}
  }
  .slick-dots li button:hover:before {
    ${tw`opacity-100 font-black border-none`}
  }
  .slick-dots li.slick-active button:before {
    ${tw`opacity-100 font-black`}
  }
`;

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  rows: 1,
  slidesToScroll: 3,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 778,
      settings: {
        rows: 1,
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        rows: 1,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
};

const PartnersCarousel = () => {
  return (
    <Slider {...settings} className="overflow-hidden" css={logos}>
      {allLogosOptions.map(({ value, logo }) => (
        <PartnerLogo Logo={logo} key={value} alternate={value} />
      ))}
    </Slider>
  );
};

export default PartnersCarousel;
